import React, { useState, useEffect, useContext } from "react";
import ProductImg from "../assets/images/product1.webp";
import FillHeart from "../assets/images/icons/fillicon.webp";
import HeartIcon from "../assets/images/icons/heartIcon.png";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { ProductsContext } from '../context/MainContext';
 

interface Product {
  id: number; // Ensure id is of type 'number'
  name: string;
  image: string;
  category: string;
  description: string;
}

const defaultProductData: Product[] = [
  {
    id: 1, // Default product with a numeric id
    name: "Default T-shirt",
    image: ProductImg,
    category: "Default",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel felis at neque viverra convallis.",
  },
  // Additional default products if needed
];

const Products: React.FC = () => {
  const { wishlist, addProductToWishlist, removeProductFromWishlist } = useContext(ProductsContext);

  const [productData, setProductData] = useState<Product[]>(defaultProductData);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>(defaultProductData);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const { id } = useParams();

  const fetchProducts = async () => {
    try {
      const res = await axios.get(
        "https://otapi.net/service-json/BatchSearchItemsFrame",
        {
          params: {
            instanceKey: "ab56451a-8bc0-4352-af52-337b00fdd4b8",
            language: "az",
            signature: "",
            timestamp: "",
            sessionId: "",
            xmlParameters:
              "<SearchItemsParameters><CategoryId>otc-463</CategoryId></SearchItemsParameters>",
            framePosition: 0,
            frameSize: 100,
            blocklist: "SubCategories",
          },
        }
      );
      console.log("API Response:", res.data);

      const itemsContent = res.data.Result?.Items?.Items?.Content || [];

      if (Array.isArray(itemsContent) && itemsContent.length > 0) {
        const products = itemsContent.map((item) => ({
          id: Number(item.Id), // Ensure id is a number
          name: item.Title,
          image: item.MainPictureUrl || ProductImg,
          category: item.CategoryId,
          description: item.Description,
        }));
        setProductData(products);
      } else {
        setProductData(defaultProductData);
      }
    } catch (error) {
      setProductData(defaultProductData);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    setFilteredProducts(productData);
  }, [productData]);

  const handleWishlistToggle = (product: Product) => {
    wishlist.some(item => item.id === product.id)
      ? removeProductFromWishlist(product.id)
      : addProductToWishlist(product);
  };

  return (
    <section className="products">
      <div className="container">
        <div className="row">
          <div className="productFilter">
            <div className="productFilterQuery">
              {/* <button onClick={() => setFilteredProducts(productData)}>
                All Products
              </button> */}
              {/* Add additional filter buttons as needed */}
            </div>
          </div>

          <div className="cardComp">
            {filteredProducts.map((product) => (
              <div className="productCard" key={product.id}>
                <div className="cardImg">
                  <img src={product.image || ProductImg} alt={product.name} />
                  <Link to={`/singleProduct/${product.id}`} state={{ product }}>
                    Quick View
                  </Link>
                </div>
                <div className="cardInfo">
                  <div className="cardTitle">
                    <p>{product.name}</p>
                  </div>
                  <div className="cardIcon">
                    <button onClick={() => handleWishlistToggle(product)}>
                      <img
                        src={
                          wishlist.some(item => item.id === product.id) ? FillHeart : HeartIcon
                        }
                        alt="favorite icon"
                      />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Products;
