import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import ProductImg from "../assets/images/product1.webp";
import FillHeart from "../assets/images/icons/fillicon.webp";
import HeartIcon from "../assets/images/icons/heartIcon.png";
import { Link } from "react-router-dom";
import { ProductsContext } from "../context/MainContext";
import Filter from "./Filter";

interface Category {
  Id: string;
  Name: string;
  Children?: Category[]; // Optional subcategories
}

interface Product {
  id: number;
  name: string;
  image: string;
  category: string;
  description: string;
}

const defaultProductData: Product[] = [
  {
    id: 1,
    name: "Default T-shirt",
    image: ProductImg,
    category: "Default",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
];

const Products: React.FC = () => {
  const { wishlist, addProductToWishlist, removeProductFromWishlist } =
    useContext(ProductsContext);

  const [productData, setProductData] = useState<Product[]>(defaultProductData);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>(defaultProductData);
  const [loading, setLoading] = useState<boolean>(true);
  const [category, setCategory] = useState<string>(""); // Selected category
  const [categories, setCategories] = useState<Category[]>([]); // Main categories
  const [subCategories, setSubCategories] = useState<Category[]>([]); // Subcategories
  const [error, setError] = useState<string | null>(null); // Error handling

  // Fetch categories from API
  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      try {
        const response = await axios.get("https://otapi.net/service-json/GetBriefCatalog", {
          params: {
            instanceKey: "ab56451a-8bc0-4352-af52-337b00fdd4b8",
            language: "en",
            signature: "",
            timestamp: "",
          },
        });

        const fetchedCategories: Category[] = response.data.Result?.Roots || [];
        setCategories(fetchedCategories);
      } catch (err) {
        setError("Failed to fetch categories");
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  

  // Fetch products based on selected category
  const fetchProducts = async (category: string) => {
    try {
      setLoading(true);
      const res = await axios.get("https://otapi.net/service-json/BatchSearchItemsFrame", {
        params: {
          instanceKey: "ab56451a-8bc0-4352-af52-337b00fdd4b8",
          language: "az",
          signature: "",
          timestamp: "",
          sessionId: "",
          xmlParameters: `<SearchItemsParameters><CategoryId>${category}</CategoryId></SearchItemsParameters>`,
          framePosition: 0,
          frameSize: 100,
          blocklist: "SubCategories",
        },
      });
      const itemsContent = res.data.Result?.Items?.Items?.Content || [];
      const products = itemsContent.map((item: any) => ({
        id: Number(item.Id),
        name: item.Title,
        image: item.MainPictureUrl || ProductImg,
        category: item.CategoryId,
        description: item.Description,
      }));
      setProductData(products);
      setFilteredProducts(products);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setProductData(defaultProductData);
      setFilteredProducts(defaultProductData);
    }
  };

  useEffect(() => {
    fetchProducts(""); // Fetch all products initially
  }, []);

  // Handle filter change
  const handleFilterChange = (category: string) => {
    fetchProducts(category); // Fetch products based on selected category
  };

  // Handle category change and update subcategories
  const handleCategoryChange = (selectedCategoryId: string) => {
    if (selectedCategoryId === "") {
      // Boş kategori id'si durumunda tüm ürünleri göster
      fetchProducts("");
    } else {
      setCategory(selectedCategoryId);
  
      // Alt kategorileri güncelle
      const selectedCategory = categories.find(cat => cat.Id === selectedCategoryId);
      if (selectedCategory && selectedCategory.Children) {
        setSubCategories(selectedCategory.Children);
      } else {
        setSubCategories([]); // Alt kategori yoksa temizle
      }
  
      // Kategoriye göre ürünleri getir
      handleFilterChange(selectedCategoryId);
    }
  };

  // Handle wishlist toggle
  const handleWishlistToggle = (product: Product) => {
    if (wishlist.some((item) => item.id === product.id)) {
      removeProductFromWishlist(product.id);
    } else {
      addProductToWishlist(product);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <section className="products">
      <div className="container">
        <div className="row">
          <div className="productFilter">
            <h2>Filter Products</h2>
            <div className="categoryButtons">
              {categories.length === 0 ? (
                <p>No categories available</p>
              ) : (
                <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                  {categories.map(cat => (
                    <button
                      key={cat.Id}
                      onClick={() => handleCategoryChange(cat.Id)}
                      style={{
                        padding: "10px",
                        cursor: "pointer",
                        backgroundColor: cat.Id === category ? "lightblue" : "white",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                      }}
                    >
                      {cat.Name}
                    </button>
                  ))}
                </div>
              )}
            </div>

            {category && subCategories.length > 0 && (
              <div className="subCategories">
                <h3>Sub Categories</h3>
                <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                  <button
                    onClick={() => handleFilterChange("")}
                    style={{
                      padding: "10px",
                      cursor: "pointer",
                      backgroundColor: "white",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                    }}
                  >
                    All Sub Categories
                  </button>
                  {subCategories.map(subCat => (
                    <button
                      key={subCat.Id}
                      onClick={() => handleFilterChange(subCat.Id)}
                      style={{
                        padding: "10px",
                        cursor: "pointer",
                        backgroundColor: subCat.Id === category ? "lightblue" : "white",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                      }}
                    >
                      {subCat.Name}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className="cardComp">
            {filteredProducts.map((product) => (
              <div className="productCard" key={product.id}>
                <div className="cardImg">
                  <img src={product.image || ProductImg} alt={product.name} />
                  <Link to={`/singleProduct/${product.id}`} state={{ product }}>
                    Quick View
                  </Link>
                </div>
                <div className="cardInfo">
                  <div className="cardTitle">
                    <p>{product.name}</p>
                  </div>
                  <div className="cardIcon">
                    <button onClick={() => handleWishlistToggle(product)}>
                      <img
                        src={wishlist.some((item) => item.id === product.id)
                          ? FillHeart
                          : HeartIcon}
                        alt="favorite icon"
                      />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Products;
