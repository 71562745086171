import React, { createContext, ReactNode, useState } from "react";

// Context'in tipi
interface Product {
  id: number;
  name: string;
  image: string;
  category: string;
}

interface ProductsContextType {
  wishlist: Product[];
  addProductToWishlist: (product: Product) => void;
  removeProductFromWishlist: (id: number) => void;
}

// Başlangıç değeri
const defaultState: ProductsContextType = {
  wishlist: [],
  addProductToWishlist: () => {},
  removeProductFromWishlist: () => {},
};

// Context oluşturulması
export const ProductsContext = createContext<ProductsContextType>(defaultState);

// Context Provider bileşeni
interface ProductsProviderProps {
  children: ReactNode;
}

export const ProductsProvider: React.FC<ProductsProviderProps> = ({ children }) => {
  const [wishlist, setWishlist] = useState<Product[]>([]);

  const addProductToWishlist = (product: Product) => {
    setWishlist((prevWishlist) => {
      const existingProduct = prevWishlist.find(item => item.id === product.id);
      if (existingProduct) {
        return prevWishlist; // Mevcut ürün varsa, bir şey yapma
      } else {
        return [...prevWishlist, { ...product }]; // Yeni ürün ekle
      }
    });
  };

  const removeProductFromWishlist = (id: number) => {
    setWishlist((prevWishlist) => prevWishlist.filter(item => item.id !== id));
  };

  return (
    <ProductsContext.Provider value={{ wishlist, addProductToWishlist, removeProductFromWishlist }}>
      {children}
    </ProductsContext.Provider>
  );
};

