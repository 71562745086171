import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import ProductImg from "../assets/images/product1.webp";

interface Product {
  id: string;
  name: string;
  image: string;
  category: string;
  description: string;
  additionalImages?: string[];
  attributes?: [{ PropertyName: string; Value: string }];
  videos?: [{ Url: string; PreviewUrl: string }];
}

const SingleProduct: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const product = location.state?.product as Product; // Accessing the passed product from the previous page
  const [productAttributes, setProductAttributes] = useState<{ PropertyName: string; Value: string }[]>([]);
  const [videos, setVideos] = useState<{ Url: string; PreviewUrl: string }[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [mainImage, setMainImage] = useState<string>(product?.image || ProductImg);

  // Function to fetch product attributes
  const fetchProductAttributes = async () => {
    try {
      const res = await axios.get(
        "https://otapi.net/service-json/BatchGetItemFullInfo",
        {
          params: {
            instanceKey: "ab56451a-8bc0-4352-af52-337b00fdd4b8",
            language: "az",
            signature: "",
            timestamp: "",
            sessionId: "",
            itemParameters:
              '<Parameters AllowIncomplete="false" AllowDeleted="false" ForceUpdate="false" WaitingTime="1" SourceAreaCode="SourceAreaCode" TargetAreaCode="TargetAreaCode"/>',
            itemid: id,
            blocklist: "Description",
          },
        }
      );

      console.log("API Response:", res.data);
      const singleProductItems = res.data.Result.Item || [];

      if (Array.isArray(singleProductItems) && singleProductItems.length > 0) {
        const singleProductItem = singleProductItems[0];
        setProductAttributes(singleProductItem.Attributes || []); // Use the Attributes field
        setVideos(singleProductItem.Videos || []);
      }
    } catch (err) {
      setError("Could not fetch product attributes.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch product attributes when the component mounts or when the id changes
  useEffect(() => {
    if (id) {
      fetchProductAttributes();
    }
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <section className="singleProduct">
      <div className="container">
        <div className="row">
          <div className="productDetails">
            <div className="productDetailsImg">
              <div className="activeImg">
                <img src={mainImage} alt={product?.name || "Product Image"} />
              </div>

              <div className="detailsImg">
                <img
                  src={product?.image || ProductImg}
                  alt="Thumbnail"
                  onClick={() => setMainImage(product?.image || ProductImg)}
                  style={{
                    border: mainImage === product?.image ? "2px solid blue" : "none",
                  }}
                />
                {product?.additionalImages?.map((img, index) => (
                  <img
                    key={index}
                    src={img || ProductImg}
                    alt={`Thumbnail ${index + 1}`}
                    onClick={() => setMainImage(img)}
                    style={{
                      border: mainImage === img ? "2px solid blue" : "none",
                    }}
                  />
                ))}
              </div>
            </div>

            <div className="productDetailsInfo">
              <h2>{product?.name || "Product Title"}</h2>
              <p>{product?.description || "No description available."}</p>

              <div className="productAttributes">
                <h3>Product Attributes:</h3>
                {productAttributes.length > 0 ? (
                  <ul>
                    {productAttributes.map((attribute, index) => (
                      <li key={index}>
                        {attribute.PropertyName}: {attribute.Value}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No attributes available.</p>
                )}
              </div>

              <div className="productVideos">
                <h3>Videos:</h3>
                {videos.length > 0 ? (
                  <div className="videoSlider">
                    {videos.map((video, index) => (
                      <div key={index} className="videoThumbnail">
                        <a href={video.Url} target="_blank" rel="noopener noreferrer">
                          <button>Watch Video {index + 1}</button>
                        </a>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>No videos available.</p>
                )}
              </div>

              <div className="addtoWishlist">
                <button className="addBtn">Add To Wishlist</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SingleProduct;
