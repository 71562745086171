import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ProductsContext } from '../context/MainContext';

const Wishlist: React.FC = () => {
  const { wishlist, removeProductFromWishlist } = useContext(ProductsContext);

  const phoneNumber = '+994514273244';

  return (
    <section className="wishList">
      <div className="container">
        <div className="row">
          <div className="wishlist-container">
            <table className="wishlist-table">
              <thead>
                <tr>
                  <th>Product Image</th>
                  <th>Product Name</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {wishlist.map(item => (
                  <tr key={item.id}>
                    <td>
                      <img src={item.image} alt={item.name} className="product-image" />
                    </td>
                    <td>{item.name}</td>
                    <td>
                      <button onClick={() => removeProductFromWishlist(item.id)}>Remove</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="checkOut">
              <Link to={`https://wa.me/${phoneNumber}?text=Merhaba,%20ben%20sipariş%20hakkında%20bilgi%20almak%20istiyorum.`} target="_blank">
                Proceed to Checkout
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Wishlist;
