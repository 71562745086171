import React, { useState, useEffect } from "react";
import axios from "axios";

interface Category {
  Id: string;
  Name: string;
  Children?: Category[];
}

interface FilterProps {
  onFilterChange: (category: string) => void;
}

const Filter: React.FC<FilterProps> = ({ onFilterChange }) => {
  const [category, setCategory] = useState<string>("");
  const [categories, setCategories] = useState<Category[]>([]);
  const [subCategories, setSubCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      try {
        const response = await axios.get("https://otapi.net/service-json/GetBriefCatalog", {
          params: {
            instanceKey: "ab56451a-8bc0-4352-af52-337b00fdd4b8",
            language: "en",
            signature: "",
            timestamp: "",
          },
        });
        const fetchedCategories: Category[] = response.data.Result?.Roots || [];
        setCategories(fetchedCategories);
      } catch (err) {
        setError("Failed to fetch categories");
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryChange = (categoryId: string) => {
    setCategory(categoryId);
    const selectedCategory = categories.find(cat => cat.Id === categoryId);
    setSubCategories(selectedCategory?.Children || []);
    onFilterChange(categoryId);
  };


  if (loading) return <div>Loading categories...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="productFilter">
      <h2>Filter Products</h2>
      <div className="categoryButtons">
        {categories.length === 0 ? (
          <p>No categories available</p>
        ) : (
          <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
            {categories.map(cat => (
              <button
                key={cat.Id}
                onClick={() => handleCategoryChange(cat.Id)}
                style={{
                  padding: "10px",
                  cursor: "pointer",
                  backgroundColor: cat.Id === category ? "lightblue" : "white",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
              >
                {cat.Name}
              </button>
            ))}
          </div>
        )}
      </div>

      {category && subCategories.length > 0 && (
        <div className="subCategories">
          <h3>Sub Categories</h3>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
            <button
              onClick={() => onFilterChange("")}
              style={{
                padding: "10px",
                cursor: "pointer",
                backgroundColor: "white",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            >
              All Sub Categories
            </button>
            {subCategories.map(subCat => (
              <button
                key={subCat.Id}
                onClick={() => onFilterChange(subCat.Id)}
                style={{
                  padding: "10px",
                  cursor: "pointer",
                  backgroundColor: "white",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
              >
                {subCat.Name}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Filter;



          {/* <div className="productFilter">
            <h2>Filter Products</h2>
            <div className="productFilterQuery">
              <select value={category} onChange={handleCategoryChange}>
                <option value="">All Categories</option>
                {categories.length === 0 ? (
                  <option disabled>No categories available</option>
                ) : (
                  categories.map((cat, index) => (
                    <option key={index} value={cat.Id}>
                      {cat.Name}
                    </option>
                  ))
                )}
              </select>
            </div>

            {category && subCategories.length > 0 && (
              <div className="subCategories">
                <h3>Sub Categories</h3>
                <select onChange={(e) => handleFilterChange(e.target.value)}>
                  <option value="">All Sub Categories</option>
                  {subCategories.map((subCat, index) => (
                    <option key={index} value={subCat.Id}>
                      {subCat.Name}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div> */}
