import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; 
import { ProductsProvider } from './context/MainContext'; 
import Home from './pages/Home';
import Header from './pages/Header';
import "./assets/scss/index.scss"
import Footer from './pages/Footer';
import Shop from './pages/Shop';
import About from './pages/About';
import SingleProduct from './components/SingleProduct';
import WishList from './pages/WishList';
import Notfound from './components/Notfound';
import Filter from './components/Filter';
const App = () => {
  const handleFilterChange = (category: string) => {
    // Burada kategoriyi işleyebilirsiniz, örneğin API'yi çağırabilirsiniz
    console.log("Selected Category:", category);
  };
  return (
    <ProductsProvider>
      <Router> 
          <Header/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/shop" element={<Shop />} />
          <Route path='/about' element={<About />} />
          <Route path ='/singleProduct/:id' element={<SingleProduct />} />
          <Route path='/wishlist' element={<WishList/>}/>
          <Route path="/filter" element={<Filter onFilterChange={handleFilterChange} />} />
          <Route path="*" element={<Notfound/>} />
        </Routes>
      <Footer/> 
      </Router>
    </ProductsProvider>
  );
};

export default App;
