import React from 'react'
import { Link } from 'react-router-dom'

const Notfound = () => {
  return (
 <section className="notFound">
  <div className="container">
    <h1>404 Not Found</h1>
    <p>The page you're looking for doesn't exist.</p>
    <Link to="/">Go back to home page</Link>
  </div>
 </section>
  )
}

export default Notfound