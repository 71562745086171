import React, { useState } from "react";
import { Link } from "react-router-dom";
import SearchIcon from "../assets/images/icons/search-icon.svg";
import HeartIcon from "../assets/images/icons/heartIcon.png";

const Header = () => {
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);

  const toggleBurgerMenu = () => {
    setIsBurgerOpen(!isBurgerOpen);
  };

  return (
    <header className="header">
      <div className="container">
        <div className="row">
          <div className="logo">
            <Link to="/">
              <span className="mainTitle">Prodium</span>
              <span className="dot">.</span>
              <span className="end">az</span>
            </Link>
          </div>
          <div className="topBar"></div>
          <nav className="navBar">
            {/* Normal Nav */}
            <ul className="navList">
              <li className="navItem">
                <Link to="/">Home</Link>
              </li>
              <li className="navItem">
                <Link to="/shop">Shop</Link>
              </li>
              <li className="navItem">
                <Link to="/about">About</Link>
              </li>
              <li className="navItem">
                <Link to="/wishlist">WishList</Link>
              </li>
              <li className="navItem">
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
            {/* <div className="icons">
              <span className="searchIcon">
                <img src={SearchIcon} alt="search-icon" />
              </span>
              <span className="heartIcon">
                <img src={HeartIcon} alt="heart-icon" />
              </span>
            </div> */}
            <div className="burgerMenu">
      <div
        
        className={`burger ${isBurgerOpen ? 'open' : ''}`}
        onClick={toggleBurgerMenu}
      >
        <input id="menu-icon" type="checkbox" />
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul className={`mobileNav ${isBurgerOpen ? 'open' : ''}`}>
        <li className="navItem">
          <Link to="/">Home</Link>
        </li>
        <li className="navItem">
          <Link to="/shop">Shop</Link>
        </li>
        <li className="navItem">
          <Link to="/about">About</Link>
        </li>
        <li className="navItem">
          <Link to="/wishlist">WishList</Link>
        </li>
        <li className="navItem">
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
    </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
