import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="footerCategory">
            <h4>Categories</h4>
            <ul className="navList">
              <li className="navItem">
                <Link to="">Man</Link>
              </li>
              <li className="navItem">
                <Link to="">Woman</Link>
              </li>
              <li className="navItem">
                <Link to="">Baby</Link>
              </li>
            </ul>
          </div>
          <div className="footerCategory">
            <h4>Help</h4>
            <ul className="navList">
              <li className="navItem">
                <Link to="">Track Orders</Link>
              </li>
              <li className="navItem">
                <Link to="">Shipping</Link>
              </li>
              <li className="navItem">
                <Link to="">FAQs</Link>
              </li>
            </ul>
          </div>
          <div className="footerCategory">
            <h4>Get In Touch</h4>
            <ul className="navList">
              <p>
                Any questions? Let us know in store at 8th floor, 379 Hudson St,
                New York, NY 10018 or call us on (+1) 96 716 6879
              </p>
              <li className="navItem">
                <Link to=""></Link>
              </li>
              <li className="navItem">
                <Link to="">Shipping</Link>
              </li>
              <li className="navItem">
                <Link to="">FAQs</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
