import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { Link } from "react-router-dom";
import Catalog from "../components/Catalog";
import Products from "../components/Products";

const Home = () => {
  return (
    <main>

    <section className="home">
      <div className="container">
        <div className="row">
          <div className="homeInfo">
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="sliderCard sliderCardOne">
                  <div className="sliderInfo">
                    <h4>Woman Collection 2024</h4>
                    <p>New Season</p>
                    <Link to="/">Shop Now</Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="sliderCard sliderCardTwo">
                  <div className="sliderInfo">
                    <h4>Man Collection 2024</h4>
                    <p>Jackets & Coats</p>
                    <Link to="/">Shop Now</Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="sliderCard sliderCardThree">
                  <div className="sliderInfo">
                    <h4>Baby Collection 2024</h4>
                    <p>Baby New Season</p>
                    <Link to="/">Shop Now</Link>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
    {/* <Catalog/> */}
    <Products/>
    </main>

  );
};

export default Home;
